.workout-remains {
  font-size: 20px;
  text-align: left;
  margin-bottom: 15px;
}

.workout-text {
  text-align: left;
  font-size: 18px;
  margin-bottom: 10px;
}