
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border: 1px solid #000;
  border-collapse: collapse;
  font-size: 16px;
  margin-bottom: 15px;
  width: 100%;
}

/* .workout-table__row {
  background-color: gray;
} */

th, td {
  border: 1px solid #000;
  padding: 5px 10px;
}

button {
  display: block;
  border: 1px solid #000;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: opacity .2s;
}

button:hover {
  opacity: 0.7;
}

.app {
  min-height: 100vh;
  padding: 100px;
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}

.wrapper > * {
  width: 33%;
}
